import React from "react";
import "./style.css";
const Loader = ({height}) => {
  return (
    <div style={{ height: height }} className="LoadingContainer">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
