import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/loader";
const HomePage = lazy(() => import("./pages/home"));
const AboutUS = lazy(() => import("./pages/about-us"));
function App() {
  return (
    <Suspense fallback={<Loader height={"100vh"} />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUS />} />
      </Routes>
    </Suspense>
  );
}

export default App;
